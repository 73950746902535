<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`필요한 날짜는 언제인가요?`"
    >
    </page-header>

    <div class="ui-border-line ui-h-0 ui-mb-1"></div>

    <div class="event-date-input ui-btn" :class="{ on: !!eventDate }" @click="$router.push('event-date/calendar')">
      <icon :src="`img/common/calendar${!!eventDate ? '_purple' : ''}.png`" width="2.4rem" height="3.4rem" :cover="false" />
      {{ eventDate ? eventDate : '날짜를 선택하세요.'}}
    </div>

    <div class="event-date-directInput ui-btn" @click="() => $refs.EtcModal.show()">
      날짜 선택이 안될 때 >
    </div>

    <modal
      ref="EtcModal"
      class="etc"
      :showCb="() => modalData.etc = eventDate"
      confirm="등록"
      :confirmCb="modal => {
        eventDate = modalData.etc
        modal.hide()
      }"
    >
      <div slot="header" style="width: 100%;">
        <p class="text-center ui-weight-5" style="font-size: 24px; color: #000; padding-top: 30px">날짜를 입력하세요</p>
        <p class="text-center" style="font-size: 20px;">
          연(4자리),월,일을<br>
          연속으로 입력해주세요.
        </p>

      </div>
      <div slot="body">
        <div class="ui-inputbox etc">
          <input type="text" v-mask="'####-##-##'" v-model="modalData.etc">
        </div>
      </div>
    </modal>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Icon from '@/components/common/Icon'
import Modal from '@/components/common/Modal'

export default {
  components: {
    PageHeader,
    FooterBox,
    Icon,
    Modal,
  },
  data() {
    return {
      eventDate: null,
      modalData: {
        etc: '',
      },
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        const eventDate: string = this.eventDate || ''

        if (!eventDate) return false

        return this.moment(eventDate).isValid()
      },
    },
  },
  mounted() {
    this.eventDate = sessionStorage.getItem('eventDate') || null
  },
  methods: {
    nextStep() {
      const evTime = this.moment(this.eventDate).valueOf()
      const now = Date.now()
      const after2year = now + 63072000000

      if (evTime <= now || evTime >= after2year) {
        this.$toast('과거 또는 2년 이상의 날짜는 선택할 수 없어요')
        return
      }

      sessionStorage.setItem('eventDate', this.eventDate)
      this.postOffer = { playDate: null }
      this.$parent.nextStep()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.event-date-input {
  overflow: hidden;
  font-size: 2.2rem;
  line-height: 4rem;
  color: #5a5a5a;

  .icon {
    float: left;
    margin-right: 1rem;
  }

  &.on {
    color: $color-deepLavender;
  }
}

.event-date-directInput {
  margin-top: 40px;
  font-size: 1.9rem;
  color: #a6a6a6;
  text-underline-position: under;
}
</style>
